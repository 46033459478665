import {
  MAX_CHARACTER_LIMIT
} from "@/constants"
import ThemisInput from "@/components/shared/input"

export default {
  name: "SaveFilter",
  data: () => ({
    filterName              : "",
    filterNameCharacterLimit: MAX_CHARACTER_LIMIT.FILTER_NAME
  }),
  components: {
    ThemisInput
  },
  props: {
    pDialogTitle : String,
    pFilter      : Object,
    pCancelText  : String,
    pContinueText: String,
    pIsSaving    : Boolean,
    pIsDuplicate : Boolean,
    pMode        : String
  },
  methods: {
    onSubmit() {
      if (this.isModeAdd) {
        this.$emit("submit", { name: this.filterName })
      } else {
        this.$emit("submit", { id: this.pFilter.id, name: this.filterName })
      }
    },
    onCancel() {
      this.$emit("cancel")
    }
  },
  computed: {
    isModeAdd() {
      return this.pMode === "add"
    },
    isModeEdit() {
      return this.pMode === "edit"
    },
    disableSave() {
      return this.isModeEdit && this.filterName === this.pFilter?.name
    }
  },
  watch: {
    filterName: {
      handler: function() {
        if (this.pIsDuplicate) {
          this.$emit("resetDuplicateError")
        }
      }
    },
    pFilter: {
      immediate: true,
      handler  : function(newValue) {
        if (this.isModeEdit && newValue) {
          this.filterName = this.pFilter.name
        }
      }
    }
  }
}